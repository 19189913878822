import React, { useState, useEffect } from "react"
import { blogs } from "../../content/Blogs"
import SectionTitle from "../atoms/SectionTitle"
import BlogCard from "../molecules/BlogCard"
import { blogsPage } from "../../content/BlogsPage"

const uniqueCategories = [...new Set(blogs.map(post => post.category))]
const POSTS_PER_PAGE = 4

const BlogSectionWithPagination = ({ searchQuery }) => {
  const [selectedCategory, setSelectedCategory] = useState("blogs")
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    setCurrentPage(1)
  }, [selectedCategory])

  var filteredBlogPosts = blogs.filter(post =>
    selectedCategory ? post.category === selectedCategory : true
  )

  filteredBlogPosts = searchQuery
    ? filteredBlogPosts.filter(post =>
        post.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : filteredBlogPosts

  const totalPages = Math.ceil(filteredBlogPosts.length / POSTS_PER_PAGE)

  const paginatedBlogPosts = filteredBlogPosts.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  )

  const handlePageChange = pageNumber => setCurrentPage(pageNumber)
  const handlePreviousPage = () =>
    currentPage > 1 && setCurrentPage(currentPage - 1)
  const handleNextPage = () =>
    currentPage < totalPages && setCurrentPage(currentPage + 1)

  return (
    <div className="cc-section--horizontal-space pt-24 pb-24">
      <div className="flex flex-col lg:flex-row gap-[80px] lg:gap-[30px]">
        <div className="flex-[4]">
          <SectionTitle
            title={blogsPage.title}
            titleEC="span-font-size-inherit"
          />

          {paginatedBlogPosts.length > 0 ? (
            <div>
              <div
                className="flex flex-wrap justify-start mt-4"
                style={{ rowGap: `15px` }}
              >
                {paginatedBlogPosts.map((item, index) => (
                  <div key={index} className="w-full md:w-1/2 px-2 mt-5">
                    <BlogCard
                      key={`blog_card_${index}`}
                      data={item}
                      imageEC="!h-[250px]"
                      descriptionLength="220"
                    />
                  </div>
                ))}
              </div>

              <div className="flex justify-center mt-28">
                <button
                  type="button"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className={`mx-1 px-4 py-3 border border-[#BBBBBB] rounded ${
                    currentPage === 1 ? "text-[#979797]" : "text-[#000000]"
                  } transition hover:bg-primary hover:text-primary`}
                >
                  Previous
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index}
                    type="button"
                    onClick={() => handlePageChange(index + 1)}
                    className={`mx-1 px-5 py-3 border border-[#BBBBBB] rounded ${
                      currentPage === index + 1
                        ? "bg-primary text-secondary"
                        : "text-[#000000]"
                    } transition hover:bg-primary hover:text-primary`}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  type="button"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className={`mx-1 px-4 py-3 border border-[#BBBBBB] rounded ${
                    currentPage === totalPages
                      ? "text-[#979797]"
                      : "text-[#000000]"
                  } transition hover:bg-primary hover:text-primary`}
                >
                  Next
                </button>
              </div>
            </div>
          ) : (
            <p className="mt-20">No Blogs match your search query.</p>
          )}
        </div>
        <div className="flex-[2]">
          <SectionTitle
            title="<span>Filter</span>"
            titleEC="span-font-size-inherit"
          />
          <div className="flex flex-wrap gap-4 mt-8 border border-[#1C5C4F] rounded-[6px] p-[2px]">
            {uniqueCategories.map((category, index) => (
              <button
                key={index}
                type="button"
                onClick={() => setSelectedCategory(category)}
                className={`rounded-[6px] capitalize transition flex-[1] px-4 py-2 md:py-3 text-[16px] md:text-[18px] font-[400] leading-[1.2em] text-[#7F7F7F] ${
                  selectedCategory === category
                    ? "bg-primary text-secondary"
                    : ""
                }`}
              >
                {category}
              </button>
            ))}
          </div>
          <div className="flex-[1] flex flex-col gap-[40px] md:gap-[15px] mt-16">
            <SectionTitle
              title="<span>Recent Posts</span>"
              titleEC="span-font-size-inherit"
            />
            {paginatedBlogPosts.length > 0 ? (
              paginatedBlogPosts
                .slice(0, 3)
                .map((item, index) => (
                  <BlogCard
                    key={`blog_card_${index}`}
                    data={item}
                    componenEC="md:flex-row"
                    imageEC="min-w-[140px] !min-h-[100px] !h-[100px]"
                    titleLength={40}
                    descriptonEC={`hidden`}
                  />
                ))
            ) : (
              <p className="mt-6">No Blogs match your search query.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogSectionWithPagination
