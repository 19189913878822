import React from "react"
import ImageUrlRenderer from "../atoms/ImageUrlRenderer"
import { Link } from "gatsby"
import { postTypesSlug, siteDetails } from "../../content/Global"
import ImageRenderer from "../atoms/ImageRenderer"
import { formatDescriptionWithDots } from "../../utils/utils"

const BlogCard = ({
  data,
  componenEC,
  imageEC,
  titleLength,
  descriptonEC,
  descriptionLength,
}) => {
  const imageUrl = ImageUrlRenderer({ img: data.image.path })

  let title
  title = formatDescriptionWithDots(data.title, titleLength || 200)

  let description
  description = formatDescriptionWithDots(
    data.description,
    descriptionLength || 55
  )

  return (
    <div className={`flex flex-col gap-[20px] ${componenEC}`}>
      <Link
        to={postTypesSlug.blogsSlug + data.slug}
        href={postTypesSlug.blogsSlug + data.slug}
      >
        <div
          className={`h-[200px] md:h-[140px] bg-center bg-cover rounded-[24px] ${imageEC}`}
          style={{ backgroundImage: `url(${imageUrl})` }}
        ></div>
      </Link>
      <div className="">
        <Link
          to={postTypesSlug.blogsSlug + data.slug}
          href={postTypesSlug.blogsSlug + data.slug}
        >
          <h4
            className={`text-[18px] leading-[1.4em] font-[600] text-[#000000] mb-1 min-h-[52px] ${data.titleEC}`}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Link>
        <p
          className={`text-[16px] leading-[1.3em] text-[#595959] ${descriptonEC}`}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        <div className="flex items-center gap-[5px] mt-3">
          <div className="max-w-[30px]">
            <ImageRenderer
              img={siteDetails.author.image.path}
              alt={siteDetails.author.image.alt}
            />
          </div>
          <div>
            <h6
              className={`text-[14px] leading-[1.4em] font-[600] text-secondary mb-[-2px]`}
            >
              {siteDetails.author.name}
            </h6>
            <p className="text-[12px] leading-[1.4em] text-[#858585]">
              {siteDetails.author.designation}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogCard
