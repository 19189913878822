import { faSearch } from "@fortawesome/free-solid-svg-icons"

export const blogsPage = {
  title: `Stay Updated with Care Casa - Your Go-To Hub for News and Blogs`,
  description: `Step into our Blogs & News corner. Here, you’ll find the latest news and insightful blogs on Specialist Disability Accommodation. Stay informed and empowered in your NDIS journey.`,
  googleReviewText: `Thanks to the management of Care Casa, the environment is incredible. The staff and facilities are matchless. I am receiving what I am expecting. Care Casa -  a place of autonomy and support.`,
  formDetails: {
    formFieldIcon: faSearch,
    formFieldPlaceholderText: `Search keywork or tag...`,
    buttonText: `Search`,
  },
  pageLongDescription: `Warmly welcome to our blogs & News hub - a dedicated space for learning about Specialist Disability Accommodation (SDA). In this section, we will equip you with a dynamic blend of the most recent industry news, expert advice, and inspiring blog posts to guide you through the world of disability accommodation. Commencing from in-depth blog posts and practical tips to groundbreaking announcements and success stories, this is your go-to resource. Stay tuned for updates on SDA developments, perceptive blogs, and news to uncover how we’re progressing in disability accommodation and making a considerable difference in the lives of NDIS participants, keeping you informed and empowered on your NDIS journey. `,
}
