import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { contactSection } from "../content/Common"
import InnerHeader from "../components/molecules/InnerHeader"
import { blogsPage } from "../content/BlogsPage"
import BlogSectionWithPagination from "../components/common/BlogSectionWithPagination"
import ContactSection from "../components/common/ContactSection"
import BrandParagraph from "../components/atoms/BrandParagraph"

const Blogs = () => {
  const [searchQuery, setSearchQuery] = useState("")

  return (
    <Layout>
      <Seo title="Blogs" />

      <InnerHeader
        title={blogsPage.title}
        description={blogsPage.description}
        googleReviewText={blogsPage.googleReviewText}
        isSearchBox={true}
        formDetails={blogsPage.formDetails}
        setSearchQuery={setSearchQuery}
      />

      {!searchQuery && (
        <div className="cc-section--horizontal-space pt-12 lg:pt-16">
          <BrandParagraph
            text={blogsPage.pageLongDescription}
            textEC={`text-center`}
          />
        </div>
      )}

      <BlogSectionWithPagination searchQuery={searchQuery} />

      <ContactSection data={contactSection} sectionTitleAlignment={`center`} />
    </Layout>
  )
}

export default Blogs
